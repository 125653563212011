.overlay-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(128, 128, 128, 0.5);
  filter: blur(3px);
  border-radius: 4px;
  width: auto;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-weight: bold;
}
