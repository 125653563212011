/* latin-ext */
@font-face {
  font-family: '__DM_Sans_05e5f9';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_05e5f9';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_05e5f9';src: local("Arial");ascent-override: 94.90%;descent-override: 29.66%;line-gap-override: 0.00%;size-adjust: 104.53%
}.__className_05e5f9 {font-family: '__DM_Sans_05e5f9', '__DM_Sans_Fallback_05e5f9';font-style: normal
}

.test_custom-layout__fVd5g{width:100%;min-height:45rem;margin-right:auto;margin-left:auto;padding-right:calc(var(--bs-gutter-x)*.5);padding-left:calc(var(--bs-gutter-x)*.5);display:flex;flex-direction:column;--bs-gutter-x: 0;--bs-gutter-y: 0}@media(min-width: 576px){.test_custom-layout__fVd5g{max-width:700px}}@media(min-width: 768px){.test_custom-layout__fVd5g{max-width:950px}}@media(min-width: 992px){.test_custom-layout__fVd5g{max-width:1150px}}@media(min-width: 1200px){.test_custom-layout__fVd5g{max-width:1300px}}@media(min-width: 1400px){.test_custom-layout__fVd5g{max-width:1420px}}@media(min-width: 1200px){.test_custom-layout__fVd5g{flex-direction:row}}
.banner-container{--animation-duration: 35s;background-color:#fff;color:#000;overflow:hidden;width:100%;height:30px;display:flex;align-items:center;position:relative}@media(max-width: 1024px){.banner-container{--animation-duration: 15s}}.banner-content{display:inline-block;white-space:nowrap;position:absolute;animation:slideInOut var(--animation-duration) linear infinite;font-size:.8rem;color:#000}@keyframes slideInOut{0%{left:100%}50%{left:0}100%{left:-100%}}
.bonus-row{display:flex;flex-direction:column;border-bottom:2px solid var(--primary-color)}.bonus-row:hover{background-color:var(--primary-color)}
[data-theme=dark]{--hightlight-color: #ff4d4d}[data-theme=light]{--hightlight-color: #b30000}.fixturesList{flex:1 1 auto;overflow-y:auto;font-size:.8rem}@keyframes dynamicHighlight{0%{transform:translateX(2px);color:rgba(255,0,0,.9529411765)}50%{transform:translateX(-2px);color:var(--hightlight-color)}100%{transform:translateX(2px);color:rgba(255,0,0,.9529411765)}}.playing{animation:dynamicHighlight 2s infinite}
@media(max-width: 767px){.favoriteLeagues-list{display:grid;grid-template-columns:repeat(2, 1fr)}}@media(min-width: 768px)and (max-width: 1199px){.favoriteLeagues-list{display:grid;grid-template-columns:repeat(3, 1fr)}}
.footer{width:100%;position:relative;background:linear-gradient(var(--primary-color) 40%, var(--secondary-color) 100%);margin-top:14rem;font-size:.9rem}@media(max-width: 1199px){.footer{margin-top:5rem}}
.overlay-icons{display:flex;align-items:center;justify-content:center;z-index:1;background-color:rgba(128,128,128,.5);filter:blur(3px);border-radius:4px;width:auto}.text-overlay{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:2;font-weight:bold}
.header{width:100%;position:-webkit-sticky;position:sticky;top:0;background:linear-gradient(var(--secondary-color) 20%, rgba(255, 255, 255, 0) 100%);-webkit-backdrop-filter:blur(7px);backdrop-filter:blur(7px);z-index:200000}.logo{display:none}@media(max-width: 767px){.logo{width:2.8rem}}.word-logo{display:none}@media(max-width: 767px){.word-logo{width:6rem}}.dropdown-menu-container{max-height:0;opacity:0;overflow:hidden;transition:max-height .4s ease,opacity .4s ease}.dropdown-menu-container.open{max-height:-moz-fit-content;max-height:fit-content;opacity:1}.dropdown-menu-container.close{max-height:0;opacity:0}
